import { createSlice } from '@reduxjs/toolkit';

export const reactiveSlice = createSlice({
  name: 'reactive',
  initialState: {
    identity: '',
  },
  reducers: {
    setForm: (state, { payload }) => {
      return (state = payload);
    },
  },
});

export const { setForm } = reactiveSlice.actions;

export default reactiveSlice.reducer;
