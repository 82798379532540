import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import AutoComplete from '@/lib/model/api/autoComplete';
import { store } from '@/lib/store';

export const api = createApi({
  reducerPath: 'api/autocomplete',
  refetchOnMountOrArgChange: true,
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/autocomplete'
  }),

  endpoints: builder => ({
    getCountry: builder.query<AutoComplete, any>({
      query: () => ({
        url: 'countries',
        params: {
          lang: store.getState().config.language,
        },
      }),
      transformResponse: ({ data }) => data,
    }),

    getState: builder.mutation<AutoComplete, any>({
      query: ({
        country,
        id,
        state_name,
      }) => ({
        url: 'states',
        params: {
          country,
          id,
          lang: store.getState().config.language,
          state_name,
          start: 0,
          limit: 20,
        },
      }),
      transformResponse: ({ data }) => data,
    }),

    getCities: builder.mutation<AutoComplete, any>({
      query: ({
        city_name,
        country,
        id,
        state,
      }) => ({
        url: 'cities',
        params: {
          city_name,
          country,
          id,
          lang: store.getState().config.language,
          state,
          start: 0,
          limit: 20,
        },
      }),
      transformResponse: ({ data }) => data,
    }),
    
    getDisability: builder.mutation<AutoComplete, any>({
      query: ({ lang }) => ({
        url: 'disabilities',
        params: {
          lang,
        },
      }),
      transformResponse: ({ data }) => data,
    }),

    getStudyPeriod: builder.mutation<AutoComplete, any>({
      query: ({ lang }) => ({
        url: 'studyPeriod',
        params: {
          lang,
        },
      }),
      transformResponse: ({ data }) => data,
    }),

    getWorkMode: builder.mutation<AutoComplete, any>({
      query: ({ lang }) => ({
        url: 'workMode',
        params: {
          lang,
        },
      }),
      transformResponse: ({ data }) => data,
    }),

    getColleges: builder.mutation<AutoComplete, any>({
      query: ({
        fantasy_name,
        id,
        lang,
      }) => ({
        url: 'colleges',
        params: {
          fantasy_name,
          id,
          lang,
          limit: 20,
          start: 0,
        },
      }),
      transformResponse: ({ data }) => data,
    }),

    getCampus: builder.mutation<AutoComplete, any>({
      query: ({
        college_id,
        lang,
      }) => ({
        url: 'campus',
        params: {
          college_id,
          lang,
        },
      }),
      transformResponse: ({ data }) => data,
    }),

    getCourses: builder.mutation<AutoComplete, any>({
      query: ({
        id,
        lang,
        name,
      }) => ({
        url: 'courses',
        params: {
          id,
          lang,
          name,
          limit: 20,
          start: 0,
        },
      }),

      transformResponse: ({ data }) => data,
    }),
    
    getEducationalLevel: builder.mutation<AutoComplete, any>({
      query: ({ lang }) => ({
        url: 'educationalLevel',
        params: {
          lang,
        },
      }),
      transformResponse: ({ data }) => data,
    }),    

    getFlags: builder.query<AutoComplete, any>({
      query: () => 'flags',
      transformResponse: ({ data }) => data,
    }),

    getEthnicities: builder.mutation<AutoComplete, any>({
      query: ({ lang }) => ({
        url: 'ethnicities',
        params: {
          lang,
        },
      }),
      transformResponse: ({ data }) => data,
    }),

    getGender: builder.mutation<AutoComplete, any>({
      query: ({ lang }) => ({
        url: 'gender',
        params: {
          lang,
        },
      }),
      transformResponse: ({ data }) => data,
    }),

    getMarital: builder.mutation<AutoComplete, any>({
      query: ({ lang }) => ({
        url: 'marital',
        params: {
          lang,
        },
      }),
      transformResponse: ({ data }) => data,
    }),
  }),
});

export const {
  useGetCampusMutation,
  useGetCitiesMutation,
  useGetCollegesMutation,
  useGetCountryQuery,
  useGetCoursesMutation,
  useGetDisabilityMutation,
  useGetEducationalLevelMutation,
  useGetEthnicitiesMutation,
  useGetFlagsQuery,
  useGetGenderMutation,
  useGetMaritalMutation,
  useGetStateMutation,
  useGetStudyPeriodMutation,
  useGetWorkModeMutation,
} = api;
