import { createSlice } from '@reduxjs/toolkit';

export const registerSlice = createSlice({
  name: 'step1',
  initialState: {
    birth_date: undefined,
    disability: undefined,
    document: '',
    ethnicity: undefined,
    full_name: '',
    gender: undefined,
    has_disability: undefined,
    has_medical_report: undefined,
    marital_status: undefined,
    medical_report: undefined,
    nationality: undefined,
    password: '',
    uses_device: undefined,
  },
  reducers: {
    setForm: (state, { payload }) => {
      return (state = {
        ...state,
        ...payload,
      });
    },
  },
});

export const { setForm } = registerSlice.actions;

export default registerSlice.reducer;
