import { FC } from 'react';
import { AppProps } from 'next/app';
import { Provider as StoreProvider } from 'react-redux';
import ThemeProvider from '@/lib/provider';
import { store } from '@/lib/store';
import GlobalStyle from '@/lib/styles/GlobalStyle';

import '@/lib/lang/config';

const App: FC<AppProps | any> = ({ Component, pageProps }) => {
  if (!process.env.IS_PRODUCTION) {
    require('@/lib/mock/api');
  }

  return (
    <StoreProvider store={store}>
      <ThemeProvider>
        <GlobalStyle />
        <Component {...pageProps} />
      </ThemeProvider>
    </StoreProvider>
  );
};

export default App;
