import { createSlice } from '@reduxjs/toolkit';

export const registerSlice = createSlice({
  name: 'step3',
  initialState: {
    zip_code: '',
    address: '',
    neighborhood: undefined,
    number: '',
    complement: '',
    city: undefined,
    state: undefined,
    country: undefined,
  },
  reducers: {
    setForm: (state, { payload }) => {
      return (state = {
        ...state,
        ...payload,
      });
    },
  },
});

export const { setForm } = registerSlice.actions;

export default registerSlice.reducer;
