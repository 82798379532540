import { createSlice } from '@reduxjs/toolkit';

export const registerSlice = createSlice({
  name: 'register',
  initialState: {
    mobile_phone: '',
    mobile_phone_ddd: '',
    mobile_phone_ddi: '',
    email: '',
    accept: false,
    doesnt_have_mobile_phone: true,
  },
  reducers: {
    setForm: (state, { payload }) => {
      return (state = payload);
    },
  },
});

export const { setForm } = registerSlice.actions;

export default registerSlice.reducer;
