import { createSlice } from '@reduxjs/toolkit';

export const registerSlice = createSlice({
  name: 'step2',
  initialState: {
    college: undefined,
    conclusion_prevision_month: undefined,
    conclusion_prevision_year: undefined,
    course: undefined,
    name_college_not_found: undefined,
    record_student: undefined,
    educational_level: undefined,
    study_period: undefined,
    work_mode: undefined,
  },
  reducers: {
    setForm: (state, { payload }) => {
      return (state = {
        ...state,
        ...payload,
      });
    },
  },
});

export const { setForm } = registerSlice.actions;

export default registerSlice.reducer;
