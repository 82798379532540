import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Response from '@/lib/model/api/response';
import { store } from '@/lib/store';

export const api = createApi({
  reducerPath: 'api/register',
  baseQuery: fetchBaseQuery({ baseUrl: '/api/registry' }),
  endpoints: builder => ({
    collegeNotFound: builder.mutation<Response, any>({
      query: ({ authorization, ...payload }) => ({
        method: 'POST',
        url: '/collegeNotFound',
        headers: {
          authorization,
        },
        body: {
          ...payload,
        },
      }),
      transformResponse: ({ data }) => data,
    }),

    document: builder.mutation<Response, any>({
      query: ({ authorization, document }) => ({
        method: 'GET',
        url: 'document',
        headers: {
          authorization,
        },
        params: {
          document,
        },
      }),
      transformResponse: ({ data }) => data,
    }),

    search: builder.mutation<Response, any>({
      query: ({ authorization, type, value }) => ({
        method: 'GET',
        url: 'search',
        headers: {
          authorization,
        },
        params: {
          type,
          value,
        },
      }),
      transformResponse: ({ data }) => data,
    }),

    register: builder.mutation<Response, any>({
      query: (payload) => ({
        method: 'POST',
        url: '',
        body: {
          ...payload,
        },
      }),
      transformResponse: ({ data }) => data,
    }),

    resend: builder.mutation<any, any>({
      query: ({ ...payload }) => ({
        method: 'POST',
        url: 'resend',
        body: {
          ...payload,
        },
      }),
      transformResponse: ({ data }) => data,
    }),

    step1: builder.mutation<Response, any>({
      query: ({ authorization, ...payload }) => ({
        method: 'POST',
        url: 'personal',
        headers: {
          authorization,
        },
        body: {
          ...payload,
        },
      }),
      transformResponse: ({ data }) => data,
    }),

    step2: builder.mutation<Response, any>({
      query: ({ authorization, ...payload }) => ({
        method: 'POST',
        url: 'teaching',
        headers: {
          authorization,
        },
        body: {
          ...payload,
        },
      }),
      transformResponse: ({ data }) => data,
    }),

    step3: builder.mutation<Response, any>({
      query: ({ authorization, ...payload }) => ({
        method: 'POST',
        url: 'location',
        headers: {
          authorization,
        },
        body: {
          ...payload,
          lang: store.getState().config.language,
        },
      }),
      transformResponse: ({ data }) => data,
    }),

    social: builder.mutation<Response, any>({
      query: (payload) => ({
        method: 'POST',
        url: 'social',
        body: {
          ...payload,
        },
      }),
      transformResponse: ({ data }) => data,
    }),

    hash: builder.mutation<Response, any>({
      query: ({ hash }) => ({
        method: 'GET',
        url: 'email',
        params: {
          hash,
        },
      }),
      transformResponse: ({ data }) => data,
    }),
  }),
});

export const {
  useCollegeNotFoundMutation,
  useDocumentMutation,
  useHashMutation,
  useRegisterMutation,
  useResendMutation,
  useSearchMutation,
  useSocialMutation,
  useStep1Mutation,
  useStep2Mutation,
  useStep3Mutation,
} = api;
