import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    debug: process.env.NODE_ENV !== 'production',
    defaultNS: 'translations',
    fallbackLng: 'pt',
    lng: 'pt',
    resources: {
      en: {
        translations: require('@/lib/lang/en/translations.json'),
      },
      pt: {
        translations: require('@/lib/lang/pt/translations.json'),
      },
      es: {
        translations: require('@/lib/lang/es/translations.json'),
      },
    },
    ns: ['translations'],
  });

i18n.languages = ['pt', 'en', 'es'];

export default i18n;
