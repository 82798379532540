import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { api as authenticateApi } from '@/lib/service/authenticate';
import { api as autocompleteApi } from '@/lib/service/autocomplete';
import { api as emailApi } from '@/lib/service/email';
import { api as registerApi } from '@/lib/service/register';
import { api as searchApi } from '@/lib/service/search';
import authenticate from './authenticate';
import config from './config';
import confirmation from './confirmation';
import errors from './errors';
import reactive from './reactive';
import register from './register';
import resend from './resend';
import step1 from './step1';
import step2 from './step2';
import step3 from './step3';

export const store = configureStore({
  reducer: {
    authenticate,
    config,
    confirmation,
    errors,
    reactive,
    register,
    resend,
    step1,
    step2,
    step3,
    [authenticateApi.reducerPath]: authenticateApi.reducer,
    [autocompleteApi.reducerPath]: autocompleteApi.reducer,
    [emailApi.reducerPath]: emailApi.reducer,
    [registerApi.reducerPath]: registerApi.reducer,
    [searchApi.reducerPath]: searchApi.reducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware()
      .concat(
        authenticateApi.middleware,
        autocompleteApi.middleware,
        emailApi.middleware,
        registerApi.middleware,
        searchApi.middleware,
      ),
});

setupListeners(store.dispatch);
