import { createSlice } from '@reduxjs/toolkit';

export const registerSlice = createSlice({
  name: 'errors',
  initialState: {
    zip_code: {
      hasError: false,
      step: 3
    },
    city: {
      hasError: false,
      step: 3
    },
    complement: {
      hasError: false,
      step: 3
    },
    course: {
      hasError: false,
      step: 2
    },
    has_disability: {
      hasError: false,
      step: 1
    },
    birth_date: {
      hasError: false,
      step: 1
    },
    course_duration: {
      hasError: false,
      step: 2
    },
    college: {
      hasError: false,
      step: 2
    },
    marital_status: {
      hasError: false,
      step: 1
    },
    ethnicity: {
      hasError: false,
      step: 1
    },
    conclusion_prevision_year: {
      hasError: false,
      step: 2
    },
    conclusion_prevision_month: {
      hasError: false,
      step: 2
    },
    address: {
      hasError: false,
      step: 3
    },
    number: {
      hasError: false,
      step: 3
    },
    nationality: {
      hasError: false,
      step: 1
    },
    educational_level: {
      hasError: false,
      step: 2
    },
    full_name: {
      hasError: false,
      step: 1
    },
    country: {
      hasError: false,
      step: 3
    },
    study_period: {
      hasError: false,
      step: 2
    },
    document: {
      hasError: false,
      step: 1
    },
    password: {
      hasError: false,
      step: 1
    },
    gender: {
      hasError: false,
      step: 1
    },
    mobile_phone: {
      hasError: false,
      step: 3
    },
    phone: {
      hasError: false,
      step: 3
    },
    disability: {
      hasError: false,
      step: 1
    },
    state: {
      hasError: false,
      step: 3
    }
  },
  reducers: {
    setErrors: (state, { payload }) => {
      const actual = state[payload];
      return (state = {
        ...state,
        [payload]: {
          ...actual,
          hasError: true,
        }
      });
    },
  },
});

export const { setErrors } = registerSlice.actions;

export default registerSlice.reducer;
