import { createSlice } from '@reduxjs/toolkit';

export const authenticateSlice = createSlice({
  name: 'authenticate',
  initialState: {
    identity: '',
    password: '',
    tokenSent: false,
  },
  reducers: {
    setForm: (state, { payload }) => {
      return (state = {
        ...state,
        ...payload
      });
    },
  },
});

export const { setForm } = authenticateSlice.actions;

export default authenticateSlice.reducer;
