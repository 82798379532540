import { createSlice } from '@reduxjs/toolkit';

export const configSlice = createSlice({
  name: 'config',
  initialState: {
    feedback: {
      errors: {},
      message: '',
      show: false,
      type: '',
    },
    authorization: '',
    recoveryPass: {
      expired: false,
    },
    resetPass: {
      authorization: '',
      timeout: 5000,
    },
    language: 'pt',
    languageFormat: 'pt',
    step: 'register',
    theme: 'cia',
    mock: {
      delay: 3000,
      method: 'GET',
      mockAll: false,
      path: '/api/',
      response: '{"status": "ok"}',
      responseHeader: [],
      statusCode: 200,
      useMocked: false,
      useResponseDefault: true,
    },
  },
  reducers: {
    setAuthorization: (state, { payload }) => ({
      ...state,
      authorization: payload,
    }),
    setTheme: (state, { payload }) => ({
      ...state,
      theme: payload,
    }),
    setStep: (state, { payload }) => ({
      ...state,
      step: payload,
    }),
    setMock: (state, { payload }) => ({
      ...state,
      mock: payload,
    }),
    setResetPass: (state, { payload }) => ({
      ...state,
      resetPass: payload,
      step: 'reset_password',
    }),
    setRecoveryPass: (state, { payload }) => ({
      ...state,
      recoveryPass: payload,
      step: 'recovery_password',
    }),
    setLanguageFormat: (state, { payload }) => ({
      ...state,
      languageFormat: payload,
      language: payload,
    }),
    setFeedback: (state, { payload }) => ({
      ...state,
      feedback: payload,
    }),
    setTwoAuth: (state, { payload }) => ({
      ...state,
      mfa: payload,
    }),
  },
});

export const {
  setAuthorization,
  setFeedback,
  setLanguageFormat,
  setMock,
  setRecoveryPass,
  setResetPass,
  setStep,
  setTwoAuth,
  setTheme,
} = configSlice.actions;

export default configSlice.reducer;
