export default {
  errorColor: '#f00',
  infoColor: '#00eaff',
  primaryColor: '#0635da',
  processingColor: '#ff00ee',
  successColor: '#379a10a0',
  warningColor: '#ffc400',
  paddingButton: '13px 39px',
  radioBackground: '#00eaff',
  borderRadius: '16px',
  whiteBackground: '#fffeee',
};
