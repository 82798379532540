import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const api = createApi({
  reducerPath: 'api/search',
  refetchOnMountOrArgChange: process.env.NODE_ENV !== 'production',
  baseQuery: fetchBaseQuery({ baseUrl: '/api/search' }),
  endpoints: builder => ({
    getAddress: builder.mutation<any, any>({
      query: ({ address, state, city }) => ({
        url: 'address',
        params: {
          address,
          state,
          city,
        },
      }),
      transformResponse: ({ data }) => data,
    }),

    getDocument: builder.mutation<any, any>({
      query: ({ document, authorization }) => ({
        url: 'document',
        headers: {
          authorization,
        },
        params: {
          document,
        },
      }),
      transformResponse: ({ data }) => data,
    }),

    getPolicy: builder.query<any, any>({
      query: () => ({
        url: 'policy',
      }),
      transformResponse: ({ data }) => data,
    }),

    getTerms: builder.query<any, any>({
      query: () => ({
        url: 'terms',
      }),
      transformResponse: ({ data }) => data,
    }),

    getZipCode: builder.mutation<any, any>({
      query: ({ zipcode }) => ({
        url: 'zipcode',
        params: {
          zipcode,
        },
      }),
      transformResponse: ({ data: { dados } }) => dados,
    }),
  }),
});

export const {
  useGetAddressMutation,
  useGetDocumentMutation,
  useGetPolicyQuery,
  useGetTermsQuery,
  useGetZipCodeMutation,
} = api;
