import { ConfigProvider } from 'antd';
import { useSelector } from 'react-redux';
import theme from '@/lib/styles';
import 'antd/dist/antd.css';
import enUS from 'antd/lib/locale/en_US';
import ptBR from 'antd/lib/locale/pt_BR';
import esES from 'antd/lib/locale/es_ES';
import 'moment/locale/es';
import { ThemeProvider } from '@emotion/react';

export default function AntdProvider({ children, storeBrand = 'cia' }) {
  
  const { brand, format } = useSelector(({ config }) => ({ brand: config.theme, format: config.languageFormat }));
  
  let locale;
  switch(format) {
    case 'es':
        locale = esES;
      break
    case 'en':
        locale = enUS;
      break
    default:
        locale = ptBR;
      break
  }
  
  const customTheme = theme({ brand: storeBrand || brand || 'cia' });

  ConfigProvider.config({
    prefixCls: 'theme',
    theme: customTheme,
  });

  return (
    <>
      <ConfigProvider locale={locale}>
        <ThemeProvider theme={customTheme}>
          {children}
        </ThemeProvider>
      </ConfigProvider>
    </>
    );
}
