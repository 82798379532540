export default {
  borderFocus: '#9EC7AE',
  borderRadius: '8px',
  borderRadiusButton: '24px',
  borderRedTest: 'border: 1px solid red',
  black: '#282828',
  colorActiveButton: '#044A4C',
  colorText: '#525960',
  colorTextInput: '#232629',
  darkYellow: '#EBC240',
  disabled: '#C6C6C6',
  errorColor: '#f00',
  fontFamily: 'Assistant',
  fontSizeButton: '18px',
  fontSizeH1: '32px',
  fontSizeH1Mobile: '28px',
  fontSizeH2: '24px',
  fontSizeH3: '22px',
  fontSizeH4: '20px',
  fontSizeH5: '18px',
  fontSizeH6: '16px',
  fontSizeLabel: '16px',
  fontSizeP: '16px',
  gray1: '#f4f5f6',
  gray2: '#fafafa',
  gray3: '#7d7d7d',
  gray4: '#d1d5da',
  gray5: '#e6e8ea',
  gray6: '#BABFC4',
  gray7: '#838C95',
  gray8: '#525960',
  gray9: '#404040',
  gray10: '#8c8c8c',
  green2: '#D9F7BE',
  green3: '#95DE64',
  greenBackground: '#bbe5cc',
  infoColor: '#20f',
  lightYellow: '#FEDF73',
  linkColor: '#0C7435',
  paddingButton: '12px',
  paddingInput: '8px 12px',
  primaryColor: '#0C7435',
  primaryColorHover: '#075B3A',
  primaryColorRGB: '12 116 53',
  primaryGreen: '#0C7435',
  primaryYellow: '#F8D35B',
  roseColor: '#E5AD9A',
  secondaryYellow: '#FFFBE6',
  processingColor: '#50B277',
  radioBackground: '#0C7435',
  secudaryColorHover: '#D3EDDE',
  secundaryColor: '#C6CD23',
  successColor: '#389e0d',
  textDisabled: '#8D8D8D',
  warningColor: '#ff0',
  whiteBackground: '#fff',
};
