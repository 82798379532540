import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Response from '@/lib/model/api/response';

export const api = createApi({
  reducerPath: 'api/email',
  baseQuery: fetchBaseQuery({ baseUrl: '/api/email' }),
  endpoints: builder => ({
    reactivate: builder.mutation<Response, any>({
      query: ({ authorization }) => ({
        method: 'GET',
        url: 'reactivate',
        headers: {
          authorization,
        },
      }),
      transformResponse: ({ data }) => data,
    }),

    requestActivation: builder.mutation<Response, any>({
      query: (payload) => ({
        method: 'POST',
        url: 'requestActivation',
        body: {
          ...payload,
        },
      }),
      transformResponse: ({ data }) => data,
    }),
  }),
});

export const {
  useReactivateMutation,
  useRequestActivationMutation,
} = api;
