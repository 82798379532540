import { createSlice } from '@reduxjs/toolkit';

export const confirmationSlice = createSlice({
  name: 'confirmation',
  initialState: {
    url: '/',
    timeout: 5000,
  },
  reducers: {
    setConfirmation: (state, { payload }) => {
      return (state = {
        ...state,
        ...payload,
      });
    },
  },
});

export const { setConfirmation } = confirmationSlice.actions;

export default confirmationSlice.reducer;
