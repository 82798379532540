import { Global, css } from '@emotion/react';

/**
 * Represents the global style component.
 * @returns The global style conponet.
 * @example
 * <GlobalStyle />
 */
const GlobalStyle = () => {
  return (
    <Global
      styles={theme => css`
        html,
        body,
        p,
        a,
        span,
        div,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          font-family: 'Assistant', sans-serif;
          margin-top: 0;
          margin-right: 0;
          margin-bottom: 0;
          margin-left: 0;
          padding-top: 0;
          padding-right: 0;
          padding-bottom: 0;
          padding-left: 0;
        }

        * {
          box-sizing: border-box;
        }

        #__next {
          background-color: #f4f5f6;
          height: 100%;
          margin: 0 auto;
        }

        body {
          background-color: #f4f5f6;
        }

        .custom-datepicker .ant-picker-panel-container {
          border-radius: 8px;
        }
        .rc-virtual-list-holder {
          padding: 8px;
        }
        .ant-select-item {
          padding: 8px;
        }
        .ant-select-item.ant-select-item-option {
          border-bottom: 1px solid #d9d9d9;
        }
        .ant-select-item.ant-select-item-option:last-child {
          border-bottom: 0;
        }
        .ant-select-item.ant-select-item-option.ant-select-item-option-active {
          color: #0c7435;
          background-color: #d3edde;
        }
        .ant-select-item.ant-select-item-option:hover {
          color: #0c7435;
          background-color: #d3edde;
        }
        .ant-select-item-option-content {
          white-space: unset;
        }
        .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
          color: #0c7435;
          background-color: #d3edde;
        }

        .ant-steps-small .ant-steps-item-title {
          font-size: 12px !important;
        }
      `}
    />
  );
};

export default GlobalStyle;
