import { createSlice } from '@reduxjs/toolkit';

export const resendSlice = createSlice({
  name: 'resend',
  initialState: {
    email: '',
  },
  reducers: {
    setForm: (state, { payload }) => {
      return (state = payload);
    },
  },
});

export const { setForm } = resendSlice.actions;

export default resendSlice.reducer;
