import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Response from '@/lib/model/api/response';

export const api = createApi({
  reducerPath: 'api/authenticate',
  baseQuery: fetchBaseQuery({ baseUrl: '/api/authenticate' }),
  endpoints: builder => ({
    login: builder.mutation<Response, any>({
      query: (payload) => ({
        method: 'POST',
        url: '',
        body: {
          ...payload,
        },
      }),
      transformResponse: ({
        data
      },
      {
        response: {
          status
        }
      }) => ({
        ...data,
        status 
      }),
    }),

    forgotPassword: builder.mutation<Response, any>({
      query: (payload) => ({
        method: 'POST',
        url: 'forgotPassword',
        body: {
          ...payload,
        },
      }),
      transformResponse: ({ data }) => data,
    }),

    validToken: builder.mutation<Response, any>({
      query: ({ authorization }) => ({
        method: 'GET',
        url: 'validToken',
        headers: {
          authorization,
        },
      }),
      transformResponse: ({ data }) => data,
    }),

    recoveryAccount: builder.mutation<Response, any>({
      query: (payload) => ({
        method: 'POST',
        url: 'recoveryAccount',
        body: {
          ...payload,
        },
      }),
      transformResponse: ({ data }) => data,
    }),

    resetPassword: builder.mutation<Response, any>({
      query: ({ authorization, payload }) => ({
        method: 'POST',
        url: 'resetPassword',
        headers: {
          authorization,
        },
        body: {
          ...payload,
        },
      }),
      transformResponse: ({ data }) => data,
    }),
    
    socialLogin: builder.mutation<Response, any>({
      query: (payload) => ({
        method: 'POST',
        url: 'social',
        body: {
          ...payload,
        },
      }),
      transformResponse: ({ data }) => data,
    }),

    sendMfa: builder.mutation<Response, any>({
      query: (payload) => ({
        method: 'GET',
        url: 'sendMfa',
        params: {
          ...payload,
        },
      }),
      transformResponse: ({ data }) => data,
    }),

    removeMfa: builder.mutation<Response, any>({
      query: (payload) => ({
        method: 'DELETE',
        url: 'removeMfa',
        body: {
          ...payload,
        },
      }),
      transformResponse: ({ data }) => data,
    }),
  }),
});

export const {
  useForgotPasswordMutation,
  useSendMfaMutation,
  useLoginMutation,
  useRecoveryAccountMutation,
  useRemoveMfaMutation,
  useResetPasswordMutation,
  useSocialLoginMutation,
  useValidTokenMutation,
} = api;
